/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import HeroBanner from '../HeroBanner/HeroBanner';
import formPageBannerImage from './assets/form-page-banner-1920x771.jpg';
import { breakpoints, colors, unitConverter as uc } from '../../../styles/base';

const FormPageBanner = ({
  textBlock: {
    align,
    description,
    descriptionColor = colors.white,
    headingText,
    headingTextColor = colors.white,
    ...textBlockProps
  },
  videoButton,
}) => {
  const formPageBannerCss = css`
    padding-top: ${uc('150px')};

    h1 {
      text-align: center;
    }

    .page-wrapper {
      justify-content: center;
    }

    &::before,
    &::after {
      opacity: 0.5;
    }

    &::before {
      background-position: left top;
      clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
    }

    &::after {
      background-position: right top;
      clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 50% 0%);
    }

    .text-block {
      padding: ${uc('120px 25px 240px')};
    }

    @media (${breakpoints.largeTablet}) {
      &::before {
        display: none;
      }

      &::after {
        clip-path: none;
      }
    }

    .slant {
      display: none;
    }

    @media (${breakpoints.mobile}) {
      .text-block {
        padding: ${uc('170px 25px 0')};
      }
    }
  `;

  return (
    <HeroBanner
      css={formPageBannerCss}
      heroImage={formPageBannerImage}
      textBlock={{
        align,
        description,
        descriptionColor,
        headingText,
        headingTextColor,
        ...textBlockProps,
      }}
      videoButton={videoButton}
    />
  );
};

FormPageBanner.propTypes = {
  ...HeroBanner.propTypes,
};

FormPageBanner.defaultProps = {
  ...HeroBanner.defaultProps,
};

export default FormPageBanner;
